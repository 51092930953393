(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "documentSearch",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            me.initSearch();
        },
        initSearch: function () {
            var $resultTableWrapper = me.$element.find('.result-table-wrapper');
            $resultTableWrapper.find('table').DataTable({
                language: {
                    url: '/build/translations/datatables.de.json'
                },
                columnDefs: [
                    {type: 'natural-nohtml', targets: 0}
                ],
                lengthChange: false,
                bFilter: false,
                pageLength: 25,
                aoColumns: [
                    null,
                    null,
                    null,
                    null,
                    {
                        bSortable: false
                    }
                ]
            });

            me.$element.on('submit', 'form.document_search', me.onSearchFormSubmit);
        },
        onSearchFormSubmit: function (event) {
            event.preventDefault();

            var $form = $(this),
                $submitButton = $form.find('button[type="submit"]'),
                data = $form.serializeArray(),
                $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                $overlay = $('body>.loader-overlay');

            $('body').append($loader);
            $overlay.addClass('is-visible');
            $submitButton.prop('disabled', 'disabled');

            $(window).on('beforeunload', me.onWindowBeforeUnload);

            $.ajax({
                url: $form.attr('action'),
                data: data,
                method: $form.attr('method'),
                success: function (response) {
                    if (response.html) {
                        var $resultTableWrapper = me.$element.find('.result-table-wrapper'),
                            $formWrapper = $form.closest('.search-documents-form-wrapper');

                        $resultTableWrapper.html(response.html.results);
                        $resultTableWrapper.find('table').DataTable({
                            language: {
                                url: '/build/translations/datatables.de.json'
                            },
                            columnDefs: [
                                {type: 'natural-nohtml', targets: 0}
                            ],
                            lengthChange: false,
                            bFilter: false,
                            pageLength: 25,
                            aoColumns: [
                                null,
                                null,
                                null,
                                null,
                                {
                                    bSortable: false
                                }
                            ]
                        });

                        $formWrapper.html(response.html.form);

                        history.pushState({}, "", "?" + $.param(data));
                    } else {
                        $(window).off('beforeunload');
                        window.location.reload();
                    }
                },
                error: function () {
                    alert('error');

                },
                complete: function (e) {
                    $loader.remove();
                    $overlay.removeClass('is-visible');
                    $submitButton.prop('disabled', '');

                    $(window).off('beforeunload');
                }
            })
        },
        onWindowBeforeUnload: function (event) {
            return "";
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
