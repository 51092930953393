import '../scss/app.scss';

import $ from 'jquery';
import 'jquery-zoom';
import 'what-input';
import 'foundation-sites';
import 'jquery.cookie';
import 'datatables.net';
import 'datatables.net-plugins/sorting/natural';
// import 'datatables.net-zf';

import './app/jquery.document-search.js';

$(document).ready(function () {
    $('body.is-ctl-inbox.is-act-index').documentSearch();
});

$(document).foundation();
